import React,{useEffect, useState} from 'react';
import { useNavigate  } from 'react-router-dom';

 function Protected(props) 
 {
    let Cmp=props.Cmp;

  const navigate = useNavigate();
 
  const [visitorn,setvisitorn]= useState([]);
  useEffect(()=>{
    document.title="View Visitor Registration - Auto EV Expo";
    const auth=localStorage.getItem("accessToken");
    auth?  navigate('/ViewVisitor'): navigate('/'); },[]);
    

  return (
    <div>
        <Cmp />
    </div>
  )
}

export default Protected;