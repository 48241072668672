import React, { useState,useEffect } from 'react';
import Logonew from "../Assets/Auto-EV-Logo-1.png";
import { useNavigate  } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol
}
from 'mdb-react-ui-kit';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


 function Login() {

 // const LOGIN_URL ="http://localhost/eventapi/index.php/auth/login"
  const LOGIN_URL = `${process.env.REACT_APP_API_URL}auth/login`;
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
 // const [navigate, setNavigate] = useState(false);
   const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(()=>{
    document.title="Login Visitor - Auto EV Expo";
  },[]);
  

  const validate=()=>{
    const errors=[];
    let isValid = true;
    const regx=/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
   if(!email)
   {
    errors.email='Email is required!';
    isValid = false;
   }
   else if(!regx.test(email))
   {
    errors.email='This is not valid Email format!';
    isValid = false;
   }

   if(!password)
   {
    errors.password='Password is required!';
    isValid = false;
   }
   setFormErrors(errors);
   return isValid;

  }

  const handleSubmit = (e) => {
    e.preventDefault();
   if(!validate())
   {

   }
   else{
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    fetch(LOGIN_URL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEmail("");
        setPassword("");
        //setNavigate(true)

         console.log(data);
         if(data.token!==null)
         {

        const access = data.token;
        localStorage.setItem('accessToken', access);
        navigate('/ViewVisitor');
         }
         else
         {
          navigate('/Login');
         }
      });
    }

  };


  
  return (
    <div>
     <Header />

    <MDBContainer className="my-5 gradient-form">
      
      <MDBRow>
        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column ms-5">

            <div className="text-center">
              <img src={Logonew}
                style={{width: '185px'}} alt="logo" />
              <h4 className="mt-1 mb-5 pb-1">We are Auto EV Expo </h4>
            </div>

            

            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label for="name" className="form-label required">Email </label>
                    <input type="email" className="form-control" id="email" name="email" value={email}  onChange={(e) => setEmail(e.target.value)} />
                    {formErrors.email && <div className="error">{formErrors.email}</div>}
                </div>
                <div className="mb-3 mt-3">
                    <label for="email" className="form-label required">Password</label>
                    <input type="password" className="form-control" id="password" name="password" value={password}   onChange={(e) => setPassword(e.target.value)}/>
                    {formErrors.password && <div className="error">{formErrors.password}</div>}
                </div>
               
                
                <button type="submit" className="btn btn-primary submit-btn">Login</button>
            </form>

           

          </div>

        </MDBCol>

        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">

            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">We are more than just a company</h4>
              <p class="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>

          </div>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
    <div className='container-fluid  bg-green'>
      <Footer />
    </div>
    </div>
  )
}

export default Login;