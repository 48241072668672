import React, { useState, useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./Payment/CheckoutForm";
import Header from "../Header/HeaderConference";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";

//const stripePromise = loadStripe('pk_test_51OrNz5SBvhpq1llqWN5zSEUw8NlhDUtEgkmHnBu2Ds1VjlScMUjydG5LOcOECvoHJtmkwIENiVON28ItdMKL8yJ000hnFQhZ4G');

const stripePromise = loadStripe('pk_live_51NJU7XSCTkp30PkSCLsA3TFObnM65r6BuVPPGnDJbTPOSIOP3hxzJLGbV9dQgTUkXxqjFsC4vSRE2XYMpCtz2WtW00adDsHrvI');
const PAYMENT_API_URL = `${process.env.REACT_APP_PAYMENT_API_URL}payment/create.php`;

const PayAmount = () => {
    const [clientSecret, setClientSecret] = useState("");
    const location=useLocation();
    console.log(location.state);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads  http://localhost/stripe-payment/create.php
        fetch(PAYMENT_API_URL, {
            method: "POST",
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify({ items: { amount:location.state.totalamount+(location.state.totalamount*18/100),name:location.state.user[0].name,city:location.state.user[0].cityname } }),
          })
          .then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret));
      }, []);
    
      const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };

  return (
    <div>
    <Header />
    <div className="container-fluid paymentbody"><div className="row  mrpayment-200">
      <div className="col-md-6 shadow-sm p-3 mb-5 bg-light rounded">

      <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-4 col-form-label">Booking Id</label>
    <div class="col-sm-8">
    BK0{location.state.bookingid}
    </div>
  </div>

      <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-4 col-form-label">Name</label>
    <div class="col-sm-8">
    {location.state.user[0].name}
    </div>
  </div>
  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Email</label>
    <div class="col-sm-8">
    {location.state.user[0].email}
    </div>
  </div>
  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Phone</label>
    <div class="col-sm-8">
    {location.state.user[0].phone}
    </div>
  </div>
 

  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Total Price</label>
    <div class="col-sm-8">
    {location.state.totalamount*2} INR
    </div>
  </div>

  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Price After Discount</label>
    <div class="col-sm-8">
    {location.state.totalamount} INR
    </div>
  </div>

  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">GST (18%)</label>
    <div class="col-sm-8">
    {location.state.totalamount*18/100} INR
    </div>
  </div>

  <div class="mb-3 row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Total Payble Amount</label>
    <div class="col-sm-8">
    {location.state.totalamount+(location.state.totalamount*18/100)} INR
    </div>
  </div>

 

       
      </div>
       <div className="col-md-6"> <div className='shadow-sm p-3 mb-5 bg-light rounded '>{clientSecret && (
        
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm bookingid={location.state.bookingid}/>
        </Elements>
       )}</div></div> </div></div>
       <Footer /></div>
       
  )
}

export default PayAmount