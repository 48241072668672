import React, { useEffect } from 'react';
import Logo from "../Assets/TECHVISON_MOUSER_STRIP_1600_X_400_PIXELS.png";
import { Link } from 'react-router-dom';


function HeaderConference() {

     useEffect(()=>{
        const authvalue=localStorage.getItem('accessToken');
        console.log(authvalue);
     });
     
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        //localStorage.removeItem("refreshToken");
        alert("Logged out");
        //navigate('/Login');
    
      };
     console.log(localStorage.getItem('accessToken'));
    return (
        <div>
            
                

               
            <nav className="navbar navbar-expand-lg navbar-dark bg-green">
                <div className="container-fluid container">
                <div className="col-md-6">
                    <a className="navbar-brand" href="https://www.techvisionsummit.com/"><img src={Logo}
                        style={{ width: '100%' }} alt="logo" /></a>
                   
                    </div>
                    <div className='col-md-8'>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                           
                             { localStorage.getItem('accessToken') ? 
                             <>
                             
                            <li className="nav-item">
                                <Link className="nav-link" to="/ViewVisitor">Visitor View </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ViewConferenceBooking">View Conference Booking </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/Userdetails">User Details</Link>
                            </li> */}
                            <li className="nav-item">
                                <a href="/" className="nav-link" onClick={handleLogout}>Logout</a>
                            </li>
                            </> : 
                            <>
                             {/* <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/VisitorRegistration">Visitor Registration</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ConferenceBooking">Conference Booking</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/Login">Login</Link>
                            </li> */}
                            </>
                          }
                            
                        </ul>
                       
                    </div>
                </div></div>
            </nav> </div>
    )
}

export default HeaderConference;