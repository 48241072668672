import React from 'react'

const Footer = () => {
    return (
        <div>
            <div className="container">
                <div className='row'>
                    <p className='footer-text'> Auto EV Expo © 2024. All rights reserved.</p>
                </div></div>
        </div>
    )
}

export default Footer