import React,{useEffect, useState,useRef} from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Header from '../Header/Header';


 function Userdetails() 
 {
  //http://localhost:3002/api/admin/visitor
   
   //const VISITOR_URL = "http://localhost/eventapi/index.php/auth/getVisitorData";
  const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getVisitorData`;

  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [visitorn,setvisitorn]= useState([]);
  useEffect(()=>{
    document.title="View Visitor Registration - Auto EV Expo";
   // const auth=localStorage.getItem("accessToken");
  //  console.log(auth);
   // auth?  navigate('/ViewVisitor'): navigate('/'); 
    axios.post(VISITOR_URL).then(res=>setvisitorn(res.data['data'])).catch(err=>console.log(err))},[]);

  return (
    <div>
      <Header/>
      <div className='container'>
      <div className='row'>
        <br/><br/><br/>
        <div className='col-md-8'><h3> Visitor Details</h3></div><div className='col-md-4 align-right'><br/>
      <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <button className='btn btn-success'> Export excel </button>

                </DownloadTableExcel></div>
     
      <table class="table table-sm" ref={tableRef}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Job Profile</th>
        <th scope="col">CompanyName</th>
        <th scope="col">Phone</th>
        <th scope="col">Email</th>
        <th scope="col">City Name</th>
        <th scope="col">How to Know</th>
        <th scope="col">Message</th>
      </tr>
    </thead>
    <tbody>
      {visitorn.map((data,i)=>(
        
      <tr>
        <th scope="row">{i+1}</th>
        <td>{data.visitor_name}</td>
        <td>{data.visitor_jobprofile}</td>
        <td>{data.visitor_companyname}</td>

        <td>{data.visitor_phone}</td>
        <td>{data.visitor_emailaddress}</td>
        <td>{data.visitor_city}</td>
        <td>{data.visitor_referby}</td>
        <td>{data.visitor_message}</td>
      </tr>
      ))
      }
      
    </tbody>
  </table></div></div></div>
  )
}

export default Userdetails;