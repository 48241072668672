import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios'

const root = ReactDOM.createRoot(document.getElementById('root'));

// Add a request interceptor
// axios.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem('accessToken');
//     if (token) {
//       config.headers['Authorization'] =token
//     }
//     // config.headers['Content-Type'] = 'application/json';
//     return config
//   },
//   error => {
//     Promise.reject(error)
//   }
// )


root.render(
  <React.StrictMode>
   
    <App />
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
